import React, { Component } from 'react';
import { Modal, Button, DatePicker, message } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import moment from 'moment';

const { MonthPicker } = DatePicker;

class MonthlyReportDialog extends Component {

  state = {
    monthDate: moment(),
  };

  componentDidMount() {
    
  }

  componentDidUpdate() {
    const {
      sendPADealingApplyMonthlyReportEmailActionStatus,
    } = this.props;

    // Send Email
    if (sendPADealingApplyMonthlyReportEmailActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('The PA dealing application monthly report email is sent successfully!');
      this.props.initSendPADealingApplyMonthlyReportEmailActionStatus();
    }

    // Send Email
    if (sendPADealingApplyMonthlyReportEmailActionStatus === ACTION_STATUS.ERROR) {
      this.props.initSendPADealingApplyMonthlyReportEmailActionStatus();
    }
  }

  queryBtnOnClicked = () => {
    const dateText = this.state.monthDate.format('YYYY-MM');
    if (!!!dateText) {
      message.warning("Please select month");
      return;
    }

    this.props.loadMonthlyReport(dateText);
  }

  sendEmailBtnOnClicked = () => {
    const dateText = this.state.monthDate.format('YYYY-MM');
    if (!!!dateText) {
      message.warning("Please select month");
      return;
    }

    this.props.sendPADealingApplyMonthlyReportEmail(dateText);
  }

  // 下载每日报表
  downloadDailyReportBtnOnClicked = () => {
    const dateText = this.state.monthDate.format('YYYY-MM');
    if (!!!dateText) {
      message.warning("Please select month");
      return;
    }

    // this.props.exportPADealingDailyReport(dateText);
    // window.location.href = "http://www.baidu.com";
  }

  monthPickerOnChanged = (date, dateString) => {
    this.setState({
      monthDate: date,
    });
  }

  // Modal Closed
  modalClosedHandle = () => {
    this.setState({
      comments: '',
    });
  }

  render() {
    const {
      loadMonthlyReportActionStatus,
      monthlyReportResult,
      monthlyReportControlModal,
      sendPADealingApplyMonthlyReportEmailActionStatus,
      sendPADealingApplyMonthlyReportEmailErrMsg,
      exportPADealingDailyReportUrl,
    } = this.props;

    const {
      isOpened,
    } = monthlyReportControlModal;

    const isLoading = loadMonthlyReportActionStatus === ACTION_STATUS.LOGINING;
    const sendEmailIsLoading = sendPADealingApplyMonthlyReportEmailActionStatus === ACTION_STATUS.LOGINING;

    let modalTitle = 'Monthly Report';
    const exportPADealingDailyReportUrlWithParam = exportPADealingDailyReportUrl + '?date-text='+this.state.dateText;

    let applyTotalCount = 0;
    let approveTotalCount = 0;
    let executeTotalCount = 0;
    let nonExecuteTotalCount = 0;
    let rejectTotalCount = 0;
    let submitProofNotOnTimeTotalCount = 0;
    let specialApproveTotalCount = 0;
    let employeePADealingMonthlyReportDTOList = [];
    let specialApprovePADealingDailyReportDTOList = [];
    let dailyCountOfTrdReqAfterApprovedDTOList = [];

    if (loadMonthlyReportActionStatus === ACTION_STATUS.SUCCESS && monthlyReportResult !== null) {
      applyTotalCount = monthlyReportResult.applyTotalCount;
      approveTotalCount = monthlyReportResult.approveTotalCount;
      executeTotalCount = monthlyReportResult.executeTotalCount;
      nonExecuteTotalCount = monthlyReportResult.nonExecuteTotalCount;
      rejectTotalCount = monthlyReportResult.rejectTotalCount;
      submitProofNotOnTimeTotalCount = monthlyReportResult.submitProofNotOnTimeTotalCount;
      specialApproveTotalCount = monthlyReportResult.specialApproveTotalCount;
      employeePADealingMonthlyReportDTOList = monthlyReportResult.employeePADealingMonthlyReportDTOList;
      specialApprovePADealingDailyReportDTOList = monthlyReportResult.specialApprovePADealingDailyReportDTOList;
      dailyCountOfTrdReqAfterApprovedDTOList = monthlyReportResult.dailyCountOfTrdReqAfterApprovedDTOList;

      specialApprovePADealingDailyReportDTOList = specialApprovePADealingDailyReportDTOList.filter(specialReport => {
        const trdReqReport = dailyCountOfTrdReqAfterApprovedDTOList.filter(trdReq => trdReq.day === specialReport.day)[0];
        return specialReport.approvedCount !== 0 || trdReqReport.trdReqAfterApprovedCountOnTDay !== 0 || trdReqReport.trdReqAfterApprovedCountOnTPlusOneDay !== 0;
      });

      employeePADealingMonthlyReportDTOList = employeePADealingMonthlyReportDTOList.filter(employeeReport => employeeReport.applyCount !== 0 || 
        employeeReport.approveCount !== 0 || employeeReport.executeCount !== 0 || employeeReport.rejectCount !== 0 || employeeReport.submitProofNotOnTimeCount !== 0);
    }

    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={1200}
          visible={isOpened}
          onOk={() => this.props.resetMonthlyReportModalDialog()}
          onCancel={() => this.props.resetMonthlyReportModalDialog()}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
          maskClosable={false}
          afterClose={this.modalClosedHandle}
        >
          <div style={{ marginBottom: 10 }}>
            <MonthPicker 
              value={this.state.monthDate}
              onChange={this.monthPickerOnChanged} 
              placeholder="Select month" />

            <Button 
              onClick={this.queryBtnOnClicked} 
              style={{ marginLeft: 10 }}
              loading={isLoading}
              type='primary'>
              Query
            </Button>

            <Button 
              onClick={this.sendEmailBtnOnClicked} 
              style={{ marginLeft: 10 }}
              loading={sendEmailIsLoading}
              type='primary'>
              Send Email
            </Button>
          </div>

          <div style={{ fontSize: 18, fontWeight: "bold", marginBottom: 10 }}>
            Report For All Employee
          </div>

          <div className="all-employee-monthly-report">
            <table border="1">
              <tbody>
                <tr>
                  <td>Applied Trade Count</td>
                  <td>{ applyTotalCount }</td>
                </tr>
                <tr>
                  <td>Approved Trade Count</td>
                  <td>{ approveTotalCount }</td>
                </tr>
                <tr>
                  <td>Executed Trade Count</td>
                  <td>{ executeTotalCount }</td>
                </tr>
                <tr>
                <td>Non-Executed Trade Count</td>
                <td>{ nonExecuteTotalCount }</td>
              </tr>
                <tr>
                  <td>Rejected Trade Count</td>
                  <td>{ rejectTotalCount }</td>
                </tr>
                <tr>
                  <td>Count Of Trade That Submit Proof Not On Time</td>
                  <td style={{ 
                    backgroundColor: submitProofNotOnTimeTotalCount !== 0 ? 'red': 'white', 
                    color: submitProofNotOnTimeTotalCount !== 0 ? 'white': 'black',
                    }}>{ submitProofNotOnTimeTotalCount }</td>
                </tr>
                <tr>
                  <td>Special Approved Trade Count</td>
                  <td>{ specialApproveTotalCount }</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* 每天特殊授权批准交易和申请后落的交易笔数 */}
          <div className="daily-all-employee-monthly-report">
            <Button 
              style={{ marginTop: 10, marginBottom: 10 }}
              href={exportPADealingDailyReportUrlWithParam}
              target="_blank"
              type='primary'>
              Download Daily Report
            </Button>
            <table border="1">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>No. of override approval</th>
                  <th>No. of trades matched with approved PA application(T日)</th>
                  <th>Trades Matched(T日)</th>
                  <th>No. of trades matched with approved PA application(T+1日)</th>
                  <th>Trades Matched(T+1日)</th>
                </tr>
              </thead>
              <tbody>
                  {
                    specialApprovePADealingDailyReportDTOList.map((specialReport) => {
                      return (
                        <tr key={specialReport.day}>
                          <td>{ specialReport.dateString }</td>
                          <td>{ specialReport.approvedCount }</td>
                          <td>{ dailyCountOfTrdReqAfterApprovedDTOList.filter(trdReq => trdReq.day === specialReport.day)[0].trdReqAfterApprovedCountOnTDay }</td>
                          <td>{ dailyCountOfTrdReqAfterApprovedDTOList.filter(trdReq => trdReq.day === specialReport.day)[0].trdReqAfterApprovedReasonOnTDay }</td>
                          <td>{ dailyCountOfTrdReqAfterApprovedDTOList.filter(trdReq => trdReq.day === specialReport.day)[0].trdReqAfterApprovedCountOnTPlusOneDay }</td>
                          <td>{ dailyCountOfTrdReqAfterApprovedDTOList.filter(trdReq => trdReq.day === specialReport.day)[0].trdReqAfterApprovedReasonOnTPlusOneDay }</td>
                        </tr>
                      );
                    })
                  }                
              </tbody>
            </table>
          </div>

          <div style={{ fontSize: 18, fontWeight: "bold", marginTop: 10, marginBottom: 10 }}>
            Report For Each Employee
          </div>

          <div className="each-employee-monthly-report">
            <table border="1">
              <thead>
                <tr>
                  <th>Employee</th>
                  <th>Applied Trade Count</th>
                  <th>Approved Trade Count</th>
                  <th>Executed Trade Count</th>
                  <th>Non-Executed Trade Count</th>
                  <th>Rejected Trade Count</th>
                  <th>Count Of Trade That Submit Proof Not On Time</th>
                </tr>
              </thead>
              <tbody>
                  {
                    employeePADealingMonthlyReportDTOList.map((employeeReport) => {
                      return (
                        <tr key={employeeReport.employee}>
                          <td>{ employeeReport.employee }</td>
                          <td>{ employeeReport.applyCount }</td>
                          <td>{ employeeReport.approveCount }</td>
                          <td>{ employeeReport.executeCount }</td>
                          <td>{ employeeReport.nonExecuteCount }</td>
                          <td>{ employeeReport.rejectCount }</td>
                          <td style={{ 
                            backgroundColor: employeeReport.submitProofNotOnTimeCount !== 0 ? 'red': 'white',
                            color: employeeReport.submitProofNotOnTimeCount !== 0 ? 'white': 'black', 
                            }}>{ employeeReport.submitProofNotOnTimeCount }</td>
                        </tr>
                      );
                    })
                  }                
              </tbody>
            </table>
          </div>
          {
            !!sendPADealingApplyMonthlyReportEmailErrMsg &&  
            <Message negative>
              <Message.Header>Response Error Message</Message.Header>
              <p>{ sendPADealingApplyMonthlyReportEmailErrMsg }</p>
            </Message>
          }
        </Modal>
      </div>
    );
  }
}

export default MonthlyReportDialog;