import React, { Component } from 'react';
import { Input, Form, Modal, Button, message, Radio, Select } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

const TextArea = Input.TextArea;
const { Option } = Select;
const codeOptions = [];

codeOptions.push(<Option key={'Junior Analyst'}>{'Junior Analyst'}</Option>);
codeOptions.push(<Option key={'Analyst'}>{'Analyst'}</Option>);
codeOptions.push(<Option key={'Associate'}>{'Associate'}</Option>);
codeOptions.push(<Option key={'Junior Associate Portfolio Manager'}>{'Junior Associate Portfolio Manager'}</Option>);

class DelRatingManagementModalDialog extends Component {

    refreshQuery() {
        const {
            userName,
            status
        } = this.props.ratingManagementQueryFields;
        this.props.loadRatingManagementInfo({userName, status})
    }
    getPageNameByMode = (mode,level) => {
        if(mode === 'inactive') {
            return 'Inactive Rating Management Info';
        } 
        if(mode === 'REPLACE'){
            if (level == 1){
                return 'Next stage -> Group';
            }
            if (level == 2){
                return 'Next stage -> StockPitch';
            }
            if (level == 3){
                return 'Next stage -> Paper';
            }
            if (level == 4){
                return 'Next stage -> Book';
            }
            return 'Next stage';
        }
        if(mode === 'ADDNOIF'){
            return 'ADDNOIF Rating Management Info';
        }
        if(mode === 'BELONG'){
            return 'Change Belong to';
        }
        if(mode === 'PROMOTION'){
            return 'Position promotion';
        }

    }
    componentDidUpdate() {
        const {
            delRatingManagementStatus,
        } = this.props;
        if(delRatingManagementStatus === ACTION_STATUS.SUCCESS) {

            const mode = this.props.delRatingManagementModal.mode;
            const info = this.getPageNameByMode(mode,0) + ' succeeded';
            message.success(info);
            this.props.initDelRatingManagementActionStatus();
            this.props.resetDelRatingManagementModal();
            this.refreshQuery();
        }
        if(delRatingManagementStatus === ACTION_STATUS.ERROR) {
            this.props.initDelRatingManagementActionStatus();
        }
    }


    onInputChange = ({name, value}) => {
        this.props.delRatingManagementChangeInput({name, value});
    }


    submitDelRatingManagement = () => {

        Modal.confirm({
            title: 'Are you sure this record?',
            onOk: () => {

                const {
                    mode,
                    idList,
                } = this.props.delRatingManagementModal;
                const {
                    id,
                    comments,
                    employee,
                    title,
                    externalTitle
                } = this.props.delRatingManagementModal.fields;
                this.props.delRatingManagement({id, mode, comments, employee, idList, title, externalTitle});
            },
            onCancel: () => {},
        });
    }

    titleMode = 'Internal'

    radioGroupOnChanged = (e) => {
        this.titleMode = e.target.value
      }

    render() {

        const {
            delRatingManagementModal,
            delRatingManagementStatus,
            userAccountOptions,
        } = this.props;
        const {
            isOpened,
            mode,
            idList,
            level,
            respErrMsg,
        } = delRatingManagementModal;


        const isSubmitLoading = delRatingManagementStatus === ACTION_STATUS.LOGINING;
        const modalTitle = this.getPageNameByMode(mode,level);
        const replaceFlag = mode ==='REPLACE' || mode === 'inactive' 
        const belongFlag =  mode ==='BELONG'
        const notifiFlag = mode === 'ADDNOIF'
        const promotionFlag =  mode ==='PROMOTION'


        const wid = mode === 'ADDNOIF' ? 900 :600
        //  wid = mode === 'PROMOTION' ? 600 :wid
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={wid}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetDelRatingManagementModal()}
                    footer={[
                        <Button key="back" onClick={() => this.props.resetDelRatingManagementModal()}>
                          Close
                        </Button>,
                        <Button key="save" type="primary" loading={isSubmitLoading} onClick={this.submitDelRatingManagement}>
                          Submit
                        </Button>,
                      ]}
                >
                    {
                    replaceFlag && 
                        <TextArea 
                        
                            style={{ width: 400 ,marginTop: 10}}
                            rows={3} 
                            placeholder="Remark"
                            onChange={(e) => {
                                this.onInputChange({ name: 'comments', value: e.target.value })
                            }}
                        />
                    }
                    {
                    notifiFlag && 
                        <TextArea 
                        
                            style={{ width: 1500 ,marginTop: 10}}
                            rows={10} 
                            placeholder="Notification Template"
                            onChange={(e) => {
                                this.onInputChange({ name: 'comments', value: e.target.value })
                            }}
                        />
                    } 
                    {
                    belongFlag &&
                        // <Input
                        //     style={{ width: 200, marginLeft: 10 }}
                        //     onChange={(e) => {
                        //         this.onInputChange({ name: 'comments', value: e.target.value })
                        //     }}
                        // />
                        <Select
                                allowClear
                                showSearch

                                style={{ width: 400, marginLeft: 10 }}
                                
                                onChange={(val) => {
                                    this.onInputChange({ name: 'comments', value: val })
                                }} 
                                placeholder="Please select Employee">
                            {userAccountOptions}
                        </Select>
                    } 
                    {
                    promotionFlag && 
                    <>
                    <div className='radioGroupWrapper'>
                        <Radio.Group defaultValue={this.titleMode} onChange={this.radioGroupOnChanged}>
                            <Radio value={ 'Internal' }>Internal</Radio>
                            <Radio value={ 'External' }>External</Radio>
                        </Radio.Group>
                        </div>
                        <br />
                        <div>
                        <Select
                            mode="multiple"
                            allowClear
                            showSearch
                            // mode='multiple'
                            style={{ width: 400, marginLeft: 10 }}
                            

                            onChange={(val) => {
                                if(this.titleMode === 'Internal') {
                                    this.onInputChange({ name: 'title', value: val.join() })
                                } else {
                                    this.onInputChange({ name: 'externalTitle', value: val.join() })
                                }
                            }} 
                            placeholder="Please select title">
                            {codeOptions}
                        </Select>
                        </div>
                    </>
                    } 
                    {
                    !!respErrMsg &&  
                    <Message negative>
                        <Message.Header>Response Error Message</Message.Header>
                        <p>{ respErrMsg }</p>
                    </Message>
                    }
                </Modal>
                
            </div>
        );
    }

}

const DelRatingManagementModalDialogForm = Form.create({ name: 'DelRatingManagementModalDialog' })(DelRatingManagementModalDialog);
export default DelRatingManagementModalDialogForm;
