import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import JsonTooltipComponent from '../../../../../utils/JsonTooltipComponent';
import StateSynchronizer from '../../../../../utils/StateSynchronizer';
import MonthlyReviewDetailPanel from './MonthlyReviewDetailPanel'
class RatingManagementGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [{
                field: 'id',
                headerName: "ID",
                cellClass: 'non-number',
                width: 80,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                cellRenderer: 'agGroupCellRenderer',

            },
            {
                field: 'employee',
                headerName: "Employee",
                cellClass: 'non-number',
                width: 100,

            },
            {
                field: 'title',
                headerName: "Title",
                cellClass: 'non-number',
                width: 120,

            },
            {
                field: 'externalTitle',
                headerName: "ExternalTitle",
                cellClass: 'non-number',
                width: 120,

            },
            {
                field: 'under',
                headerName: "Under",
                cellClass: 'non-number',
                width: 100,

            },
            {
                field: 'className',
                headerName: "ClassName",
                cellClass: 'non-number',
                width: 100,

            },
            {
                field: 'joinDate',
                headerName: "JoinDate",
                cellClass: 'non-number',
                width: 100,

            },
            {
                field: 'maxclass',
                headerName: "CIO Tutorial",
                cellClass: 'non-number',
                width: 100,
                cellStyle: this.dataColorCellStyle,
            },
            {
                field: 'minclass',
                headerName: "Group",
                cellClass: 'non-number',
                width: 120,
                cellStyle: this.dataColorCellStyle,
            },
            {
                field: 'stockpitch',
                headerName: "StockPitch",
                cellClass: 'non-number',
                width: 100,
                cellStyle: this.dataColorCellStyle,
            },
            {
                field: 'paper',
                headerName: "Paper",
                cellClass: 'non-number',
                width: 100,
                cellStyle: this.dataColorCellStyle,
            },
            {
                field: 'book',
                headerName: "Book",
                cellClass: 'non-number',
                width: 100,
                cellStyle: this.dataColorCellStyle,
            },
            {
                field: 'notes',
                headerName: "Last Notification",
                cellClass: 'non-number',
                width: 200,
                cellRenderer: 'jsonTooltipComponent'
            },
            {
                field: 'notifStatus',
                headerName: "Notification Status",
                cellClass: 'non-number',
                width: 100,
            },
            {
                field: 'notifiDate',
                headerName: "Notification Date",
                cellClass: 'non-number',
                width: 150,
            },
            {
                field: 'notifiBy',
                headerName: "Notifi By",
                cellClass: 'number',
                width: 100,
            },
            // {
            //     field: 'filepath',
            //     headerName: "Rating Management Form",
            //     cellClass: 'non-number',
            //     width: 300,
            //     cellRenderer: this.sendHistoryAttachementCellRender
            // },
        ],
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50
            },
            rowSelection: "multiple",
            context: { componentParent: this },
            frameworkComponents: {
                jsonTooltipComponent: JsonTooltipComponent,
                myDetailCellRenderer: MonthlyReviewDetailPanel,
                // tagComponent: TagComponent
              },
              detailCellRenderer: 'myDetailCellRenderer',
            statusBar: {
                statusPanels: [
                  {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                  },
                  {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
        };
    }
    dataColorCellStyle(param) {
        const status = param.value;
        if(status === 'In Progress') {
            // return {color: 'green'};
            return { background: '#fcf800 '};
        }
        if(status === 'Completed') {
            return {background: 'green'};
            // return { background: '#fcf800 '};
        }
        
        
    }
    sendHistoryAttachementCellRender = (node) => {
        const attachement = node.data.filepath;
        if(!attachement) {
            return '';
        }
        const attArray = attachement.split(';');
        const retElement = document.createElement('div');
        let first = true;
        attArray.forEach(e => {
            if(!first) {
                retElement.append(' | ');
            }
            first = false;

            const url = this.props.ratingManagementDownloadUrl + '/' + e;
            const link = document.createElement('a');
            link.innerText = e;
            link.target = '_blank';
            link.href = url;
            retElement.appendChild(link);

        });
        return retElement;
    }
    getRowNodeId = data => {
        return data.id;
    };

    onDataSelectionChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let ratingManagementInfoArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectRatingManagementInfo(ratingManagementInfoArray);
    }
    onGridReady = params => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    
        const COLUMNS_KEY = 'ratingmanage-list-grid-col-state';
    
        StateSynchronizer.syncGrid(params, this.state.columnDefs, COLUMNS_KEY);
      };
    render() {

        const {
            // query
            loadRatingManagementInfoActionStatus,
            loadRatingManagementInfoResp,
        } = this.props;


        const isLoading = loadRatingManagementInfoActionStatus === ACTION_STATUS.LOGINING;
        let loadRatingManagementInfoArray = [];
        if(!!loadRatingManagementInfoResp && !!loadRatingManagementInfoResp.data) {
            loadRatingManagementInfoArray = loadRatingManagementInfoResp.data;
        }


        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                           




                            columnDefs={this.state.columnDefs}
                            rowData={loadRatingManagementInfoArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            enableRangeSelection={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            statusBar={this.state.statusBar}
                            onGridReady={this.onGridReady}
                            frameworkComponents={this.state.frameworkComponents}
                            detailCellRenderer={this.state.detailCellRenderer}
                            onSelectionChanged={this.onDataSelectionChanged}
                            masterDetail={true}
                            resizable={true}
                            sideBar={true}
                        />
                    )}
            </div>
        );
    }
}

export default RatingManagementGrid;
